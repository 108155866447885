<template>
  <div>
	  <div class="cardTitle">缴费计划</div>
    <a-tabs v-model="activeMode">
      <a-tab-pane :key="1" tab="手动生成账单"> </a-tab-pane>
      <a-tab-pane :key="2" tab="自动生成账单"> </a-tab-pane>
      <a-tab-pane :key="3" tab="账单自动扣费"> </a-tab-pane>
    </a-tabs>
    <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm>
    <a-button type="danger" style="margin: 10px" @click="multipleDel"><a-icon type="delete" />批量删除</a-button>
    <a-button style="margin: 10px" class="add-btn" @click="addBill"><a-icon type="plus" />添加</a-button>
    <a-table v-if="activeMode==2" :columns="columns" :data-source="tableData" :pagination="pagination" @change="pageChange" 
      :row-selection="{
        selectedRowKeys: table2Keys,
        onChange: table2Change,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      ">
      <span slot="action" slot-scope="text, record">
        <a @click="onEdit(record)">编辑</a><a style="color: red;margin-left: 8px" @click="onDel(record)">删除</a>
      </span>
    </a-table>
    <a-table v-if="activeMode==3" :columns="columns" :data-source="tableData2" :pagination="pagination2" @change="pageChange2"
      :row-selection="{
        selectedRowKeys: table3Keys,
        onChange: table3Change,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      ">
      <span slot="action" slot-scope="text, record">
        <a @click="onEdit(record)">编辑</a><a style="color: red;margin-left: 8px" @click="onDel(record)">删除</a>
      </span>
    </a-table>
    <add-bill-drawer v-if="activeMode==1" :visible="addShow" @success="success" @onClose="addClose"></add-bill-drawer>
    <auto-bill-drawer v-else-if="activeMode==2" :visible="addShow" @success="success" @onClose="addClose"></auto-bill-drawer>
    <auto-bill-payment-drawer v-else-if="activeMode==3" :visible="addShow" @success="success" @onClose="addClose"></auto-bill-payment-drawer>
    <a-drawer title="编辑" :width="920" :visible="editShow" :body-style="{ paddingBottom: '80px' }" @close="editClose">
      <div class="drawer-content">
            <div style="display: flex">
                <div class="inner-content">
                    <div style="font-weight: 600">选择房屋</div>
                    <a-tree v-model="checkedKeys" @check="onCheck"
                    :selectable="false" :tree-data="roomTreeData" defaultExpandAll checkable :replace-fields="replaceFields">
                    </a-tree>
                </div>
                <div class="inner-content">
                    <a-table :data-source="editTableData" :columns="editColumns" :pagination="false"
                        :row-selection="{
                            selectedRowKeys: this.form.chargesIds,
                            onChange: selectionChoosed,
                        }"
                        :row-key="
                            (record, index) => {
                            return record.id;
                            }
                        ">
                        <template slot="title">
                            <span style="font-weight: 600">收费标准</span>
                        </template>
                    </a-table>
                    <a-form-model :rules="rules" :model="form" ref="ruleForm">
                        <a-row>
                            <a-col :span="24">
                                <a-form-model-item prop="name" label="账单计划名称">
                                    <a-input v-model="form.name"></a-input>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-model-item label="生效日期">
                                    <a-radio-group v-model="form.isLongTermEffective" :options="[{label:'长期生效',value:1},{label:'选择日期',value:2}]"></a-radio-group>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-model-item prop="selTime" label="选择计划日期">
                                    <a-range-picker style="width: 400px" v-model="selTime"  @change="timeChange" value-format="YYYY-MM-DD HH:mm:ss"></a-range-picker>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="扣费时间">
                                    <a-select v-model="form.billDateStart">
                                        <a-select-option :value="1">每月第一天</a-select-option>
                                        <a-select-option :value="2">每月最后一天</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="提前/延长">
                                    <a-select v-model="form.billDateType">
                                        <a-select-option :value="1">提前</a-select-option>
                                        <a-select-option :value="2">延长</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="天数">
                                    <a-input-number v-model="form.billDateNum"></a-input-number>天
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-form-model>
                </div>
            </div>
        </div>
        <div class="drawer-footer">
            <a-button :style="{ marginRight: '8px' }" @click="editClose">
                关闭
            </a-button>
            <a-button type="primary" @click="editSubmit"> 提交 </a-button>
        </div>
    </a-drawer>
  </div>
</template>

<script>
import addBillDrawer from "../PayPlan/addBillDrawer.vue"
import autoBillDrawer from "../PayPlan/autoBillDrawer.vue"
import autoBillPaymentDrawer from "../PayPlan/autoBillPaymentDrawer.vue"
import {findEstateCascade} from "@/api/basic/estate" //api
import { chargesList } from "@/api/payment/chargeStandardManage"; //api
import {billAutoList, getAutoDeductionBills} from "@/api/payment/payPlan" //查
import {deleteAutoGenerateBills, deleteAutoDeductionBills} from "@/api/payment/payPlan" //删
import {updateAutoGenerateBills, updateAutoDeductionBills} from "@/api/payment/payPlan" //改
import {findByIdByBillAutoId, findById} from "@/api/payment/payPlan" //回调
function deleteIds(lists) {
	// 楼栋id和房屋id有重复，导致数形控件选择不可用，把除房屋id外的全改为undefined
	lists.forEach((list) => {
		if (list.childList) {
			list.id = undefined;
			deleteIds(list.childList);
		} else {
			return;
		}
	});
}
export default {
    name: 'payPlay',
    components: {
      addBillDrawer,
      autoBillDrawer,
      autoBillPaymentDrawer
    },
    data() {
        return {
            //编辑抽屉
            form: {
                id: undefined,
                estateIds: [],
                chargesIds: [],
                name: '',
                isLongTermEffective: 1,
                autoEffectiveDateStart: '',
                autoEffectiveDateEnd: '',
                billDateStart: 1,
                billDateType: 1,
                billDateNum: 0,
            },
            rules: {
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                selTime: [{ required: true, message: "请选择时间", trigger: "blur" }],
            },
            checkedKeys: [],
            selTime: [,],
            editTableData: [],
            roomTreeData: [],
            replaceFields: {
                children: 'childList',
                title: 'name',
                key: 'id'
            },
            editColumns: [
                {title: "费用名称", dataIndex: "name"},
                {title: "计费方式", dataIndex: "billingType",
                    customRender: function (billingType) {
                        switch(billingType) {
                            case 1: return '单价*计量方式';
                            case 2: return '固定金额';
                        }
                    },
                },
                {title: "计量方式", dataIndex: "calculateType",
                    customRender: function (calculateType) {
                        switch(calculateType) {
                            case 1: return '建筑面积';
                            case 2: return '使用面积';
                            case 3: return '公摊面积';
                            case 5: return '仪表用量';
                            case 6: return '房屋数分摊';
                            case 7: return '房屋建筑面积分摊';
                            case 8: return '房屋在住人口分摊';
                        }
                    },
                },
                {title: "计量单价", dataIndex: "calculateUnit",
                    customRender: function (calculateUnit) {
                        return (calculateUnit*1).toFixed(2)
                    },
                },
                {title: "备注", dataIndex: "remarks"},
            ],
            ////////
            searchForm: {
              name: ''
            },
            formItem: [
                {
                    type: 'input',
                    label:'计划名称',
                    prop:'name',
                    placeholder:'请输入'
                },
            ],
            columns: [
                {title: "账单名称", dataIndex: "name", width: 240},
                {title: "计划有效时间开始", dataIndex: "autoEffectiveDateStart", width: 240},
                {title: "计划有效时间结束", dataIndex: "autoEffectiveDateEnd", width: 240},
                {title: "账单生成时间", dataIndex: "billDateStart", width: 176,
                customRender: function (billDateStart) {
                  switch (billDateStart) {
                  case 1:
                    return "每月第一天";
                  case 2:
                    return "每月最后一天";
                  default:
                    break;
                  }
                },},
                {title: "账单生成时间类型", dataIndex: "billDateType", width: 180,
                customRender: function (billDateType) {
                  switch (billDateType) {
                  case 1:
                    return "提前";
                  case 2:
                    return "延长";
                  default:
                    break;
                  }
                },},
                {title: "账单生成时间天数", dataIndex: "billDateNum"},
                { title: "操作",
                  dataIndex: "action",
                  key: "action",
                  width: "180",
                  fixed: "right",
                  scopedSlots: { customRender: "action" },
                },
            ],
            tableData: [],
            tableData2: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            pagination2: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            table2Keys: [],
            table3Keys: [],
            activeMode: 1,
            //手动生成账单
            addShow: false,
            editShow: false,
        }
    },
    created() {
      this.getApi()
      this.getData()
    },
    methods: {
        getApi() {
            findEstateCascade().then(res => {
                let data = res.data;
                // data.forEach(ele => {
                //     ele.childList.forEach(el => {
                //         el.checkable = false
                //     });
                //     ele.checkable = false
                // })
                this.roomTreeData = data
                deleteIds(this.roomTreeData);
            });
            chargesList({pageNum: 1,size: 1000}).then(res => {
                let data = res.data.rows;
                this.editTableData = data;
            })
        },
        getData() {
          let obj = Object.assign(this.searchForm, {size: this.pagination.pageSize,pageNum: this.pagination.current})
          billAutoList(obj).then(res => {
            this.tableData = res.data.rows;
            this.pagination.total = res.data.total;
          })
          getAutoDeductionBills(obj).then(res => {
            this.tableData2 = res.data.rows;
            this.pagination2.total = res.data.total;
          })
        },
        search(data) {
            this.searchForm = data;
            this.getData()
        },
        success() {
          this.getData();
        },
        onCheck(checkedKeys) {
            let ids = [];
            for (let id of checkedKeys) {
              if (typeof id == "number") {
                ids.push(id);
              }
            }
            this.form.estateIds = ids;
        },
        //手动生成账单
        addBill() {
          this.addShow = true
        },
        addClose() {
          this.addShow = false
        },
        //分页
        pageChange(val) {
          this.pagination = val
          this.getData()
        },
        pageChange2(val) {
          this.pagination = val
          this.getData()
        },
        //点击编辑的回调
        onEdit(val) {
          this.editShow = true
          if(this.activeMode == 2) {
            let obj = {billAutoId : val.id}
            findByIdByBillAutoId(obj).then(res => {
              let data = res.data;
              this.checkedKeys = data.estateIds
              this.form = data;
              this.selTime = [res.data.autoEffectiveDateStart,res.data.autoEffectiveDateEnd]
            })
          } else if(this.activeMode == 3) {
            let obj = {billAutoDeductionId : val.id}
            findById(obj).then(res => {
              let data = res.data;
              this.checkedKeys = data.estateIds
              this.form = data;
              this.selTime = [res.data.autoEffectiveDateStart,res.data.autoEffectiveDateEnd]
            })
          }
        },
        editClose() {
          this.editShow = false
        },
        editSubmit() {
          let obj = this.form;
          if(obj.estateIds.checked) {
            obj.estateIds = obj.estateIds.checked;
          }
          // console.log(obj)
          if(this.activeMode == 2) {
            updateAutoGenerateBills(obj).then(res => {
              if(res.code === 200){
                  this.$message.success(res.msg);
                  this.editShow = false;
                  this.getData()
              } else {
                  this.$message.error(res.msg);
              }
            })
          } else if (this.activeMode == 3) {
            updateAutoDeductionBills(obj).then(res => {
              if(res.code === 200){
                  this.$message.success(res.msg);
                  this.editShow = false;
                  this.getData()
              } else {
                  this.$message.error(res.msg);
              }
            })
          }
        },
        timeChange() {
            this.form.autoEffectiveDateStart = this.selTime[0];
            this.form.autoEffectiveDateEnd = this.selTime[1];
        },
        selectionChoosed(data) {
            this.form.chargesIds = data;
        },
        table2Change(data) {
            this.table2Keys = data;
        },
        table3Change(data) {
            this.table3Keys = data;
        },
        //删除
        onDel(val) {
          this.$confirm({
            title: "是否删除",
            icon:'close',
            onOk:async()=>{
              if(this.activeMode == 2) {
                let res = await deleteAutoGenerateBills({billAutoIds: [val.id]});
                if(res.code === 200){
                  this.$message.success(res.msg);
                  this.getData()
                } else {
                  this.$message.error(res.msg);
                }
              } else if (this.activeMode == 3) {
                let res = await deleteAutoDeductionBills({billAutoDeductionIds: [val.id]});
                if(res.code === 200){
                  this.$message.success(res.msg);
                  this.getData()
                } else {
                  this.$message.error(res.msg);
                }
              }
            },
          })
        },
        //批量删除
        multipleDel() {
          this.$confirm({
            title: "是否删除",
            icon:'close',
            onOk:async()=>{
              if(this.activeMode == 2) {
                let res = await deleteAutoGenerateBills({billAutoIds: this.table2Keys});
                if(res.code === 200){
                  this.$message.success(res.msg);
                  this.getData()
                } else {
                  this.$message.error(res.msg);
                }
              } else if (this.activeMode == 3) {
                let res = await deleteAutoDeductionBills({billAutoDeductionIds: this.table3Keys});
                if(res.code === 200){
                  this.$message.success(res.msg);
                  this.getData()
                } else {
                  this.$message.error(res.msg);
                }
              }
            },
          })
        }
    }
}
</script>

<style lang="less">
.inner-content {
    padding :8px 8px 8px 16px
}
</style>