import httpService from "@/request"

// 手动生成后台账单
export function manuallyGenerateBills(params) {
	return httpService({
		url: `/user/bill/manuallyGenerateBills`,
		method: 'post',
		data: params,
	})
}

// 自动生成后台账单计划
export function autoGenerateBills(params) {
	return httpService({
		url: `/user/billAuto/autoGenerateBills`,
		method: 'post',
		data: params,
	})
}

// 后台自动生成账单List
export function billAutoList(params) {
	return httpService({
		url: `/user/billAuto/billAutoList`,
		method: 'get',
		params: params,
	})
}

// 删除后台自动生成账单计划
export function deleteAutoGenerateBills(params) {
	return httpService({
		url: `/user/billAuto/deleteAutoGenerateBills`,
		method: 'post',
		data: params,
	})
}

// 生成后台账单自动扣费计划
export function autoDeductionBills(params) {
	return httpService({
		url: `/user/billAutoDeduction/autoDeductionBills`,
		method: 'post',
		data: params,
	})
}

// 后台自动扣费账单list
export function getAutoDeductionBills(params) {
	return httpService({
		url: `/user/billAutoDeduction/list`,
		method: 'get',
		params: params,
	})
}

// 删除后台自动扣费账单计划
export function deleteAutoDeductionBills(params) {
	return httpService({
		url: `/user/billAutoDeduction/deleteAutoDeductionBills`,
		method: 'post',
		data: params,
	})
}

// 根据账单自动生成主键id获取账单自动生成信息
export function findByIdByBillAutoId(params) {
	return httpService({
		url: `/user/billAuto/findByIdByBillAutoId`,
		method: 'get',
		params: params,
	})
}

// 根据账单自动扣费主键id获取账单自动扣费信息
export function findById(params) {
	return httpService({
		url: `/user/billAutoDeduction/findById`,
		method: 'get',
		params: params,
	})
}

// 修改自动生成后台账单计划
export function updateAutoGenerateBills(params) {
	return httpService({
		url: `/user/billAuto/updateAutoGenerateBills`,
		method: 'post',
		data: params,
	})
}

// 修改自动扣费后台账单计划
export function updateAutoDeductionBills(params) {
	return httpService({
		url: `/user/billAutoDeduction/updateAutoDeductionBills`,
		method: 'post',
		data: params,
	})
}