<template>
    <a-drawer title="添加账单自动扣费计划" :width="920" :visible="isVisibleDrawer" :body-style="{ paddingBottom: '80px' }" @close="onClose">
        <div class="drawer-content">
            <div style="display: flex">
                <div class="inner-content">
                    <a-tree v-model="checkedKeys" @check="onCheck"
                    :selectable="false" :tree-data="roomTreeData" checkable :replace-fields="replaceFields"></a-tree>
                </div>
                <div class="inner-content">
                    <a-table :data-source="tableData" :columns="columns" :pagination="false"
                        :row-selection="{
                            selectedRowKeys: this.form.chargesIds,
                            onChange: selectionChoosed,
                        }"
                        :row-key="
                            (record, index) => {
                            return record.id;
                            }
                        ">
                        <template slot="title">
                            <span style="font-weight: 600">收费标准</span>
                        </template>
                    </a-table>
                    <a-form-model :rules="rules" :model="form" ref="ruleForm">
                        <a-row>
                            <a-col :span="24">
                                <a-form-model-item prop="name" label="账单计划名称">
                                    <a-input v-model="form.name"></a-input>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-model-item label="生效日期">
                                    <a-radio-group v-model="form.isLongTermEffective" :options="[{label:'选择日期',value:2},{label:'长期生效',value:1}]"></a-radio-group>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="24">
                                <!-- <a-form-model-item prop="selTime" label="选择计划日期">
                                    <a-range-picker v-model="form.selTime"  @change="timeChange" value-format="YYYY-MM-DD HH:mm:ss"></a-range-picker>
                                </a-form-model-item> -->
                                <a-form-model-item v-if="form.isLongTermEffective == 1" prop="autoEffectiveDateStart" label="选择计划日期">
                                    <a-date-picker  v-model="form.autoEffectiveDateStart" value-format="YYYY-MM-DD HH:mm:ss"></a-date-picker>
                                </a-form-model-item>
                                <a-form-model-item v-else prop="selTime" label="选择计划日期">
                                    <a-range-picker  v-model="form.selTime"  @change="timeChange" value-format="YYYY-MM-DD HH:mm:ss"></a-range-picker>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="扣费时间">
                                    <a-select v-model="form.billDateStart">
                                        <a-select-option :value="1">每月第一天</a-select-option>
                                        <a-select-option :value="2">每月最后一天</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="提前/延长">
                                    <a-select v-model="form.billDateType">
                                        <a-select-option :value="1">提前</a-select-option>
                                        <a-select-option :value="2">延长</a-select-option>
                                    </a-select>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="8">
                                <a-form-model-item label="天数">
                                    <a-input-number v-model="form.billDateNum"></a-input-number>天
                                </a-form-model-item>
                            </a-col>
                        </a-row>
                    </a-form-model>
                </div>
            </div>
        </div>
        <div class="drawer-footer">
            <a-button :style="{ marginRight: '8px' }" @click="onClose">
                关闭
            </a-button>
            <a-button type="primary" @click="onSubmit"> 提交 </a-button>
        </div>
    </a-drawer>
</template>

<script>
import {findEstateCascade} from "@/api/basic/estate"
import { chargesList } from "@/api/payment/chargeStandardManage";
import { autoDeductionBills } from "@/api/payment/payPlan"
function deleteIds(lists) {
	// 楼栋id和房屋id有重复，导致数形控件选择不可用，把除房屋id外的全改为undefined
	lists.forEach((list) => {
		if (list.childList) {
			list.id = undefined;
			deleteIds(list.childList);
		} else {
			return;
		}
	});
}
export default {
    name: 'addBillDrawer',
    props: {
		visible: Boolean,
	},
    watch: {
		visible: {
			handler(newValue) {
				this.isVisibleDrawer = newValue;
			},
			immediate: true,
		}
	},
    mounted() {
        this.getApi()  
    },
    data() {
        return {
            form: {
                estateIds: [],
                chargesIds: [],
                name: '',
                isLongTermEffective: 2,
                autoEffectiveDateStart: '',
                autoEffectiveDateEnd: '',
                billDateStart: 1,
                billDateType: 1,
                billDateNum: 0,
                selTime: []
            },
            rules: {
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                selTime: [{ required: true, message: "请选择时间", trigger: "blur" }],
                autoEffectiveDateStart: [{ required: true, message: "请选择时间", trigger: "change" }],
            },
            isVisibleDrawer: false,
            roomTreeData: [],
            replaceFields: {
                children: 'childList',
                title: 'name',
                key: 'id'
            },
            checkedKeys: [],
            //收费标准
            tableData: [],
            columns: [
                {title: "费用名称", dataIndex: "name"},
                {title: "计费方式", dataIndex: "billingType",
                    customRender: function (billingType) {
                        switch(billingType) {
                            case 1: return '单价*计量方式';
                            case 2: return '固定金额';
                        }
                    },
                },
                {title: "计量方式", dataIndex: "calculateType",
                    customRender: function (calculateType) {
                        switch(calculateType) {
                            case 1: return '建筑面积';
                            case 2: return '使用面积';
                            case 3: return '公摊面积';
                            case 5: return '仪表用量';
                            case 6: return '房屋数分摊';
                            case 7: return '房屋建筑面积分摊';
                            case 8: return '房屋在住人口分摊';
                        }
                    },
                },
                {title: "计量单价", dataIndex: "calculateUnit",
                    customRender: function (calculateUnit) {
                        return (calculateUnit*1).toFixed(2)
                    },
                },
                {title: "备注", dataIndex: "remarks"},
            ],
            selTime: []
        }
    },
    methods: {
        getApi() {
            findEstateCascade().then(res => {
                let data = res.data;
                // data.forEach(ele => {
                //     ele.childList.forEach(el => {
                //         el.checkable = false
                //     });
                //     ele.checkable = false
                // })
                this.roomTreeData = data
                deleteIds(this.roomTreeData);
            });
            chargesList({pageNum: 1,size: 1000}).then(res => {
                let data = res.data.rows;
                this.tableData = data;
            })
        },
        onClose() {
			this.$emit("onClose");
		},
        onCheck(checkedKeys) {
            let ids = [];
			for (let id of checkedKeys) {
				if (typeof id == "number") {
					ids.push(id);
				}
			}
            this.form.estateIds = ids;
        },
        //收费标准
        selectionChoosed(data) {
            this.form.chargesIds = data;
        },
        timeChange(val) {
            this.form.autoEffectiveDateStart = this.form.selTime[0];
            this.form.autoEffectiveDateEnd = this.form.selTime[1];
        },
        success(){
			this.$emit('success')
		},
        addClose() {
            this.onClose();
            this.form = {
                estateIds: [],
                chargesIds: [],
                name: '',
                isLongTermEffective: 1,
                autoEffectiveDateStart: '',
                autoEffectiveDateEnd: '',
                billDateStart: 1,
                billDateType: 1,
                billDateNum: 0,
                selTime: []
            };
            this.checkedKeys = [];
            this.form.selTime = []
        },
        onSubmit() {
            autoDeductionBills(this.form).then(res => {
                if(res.code === 200){
					this.$message.success(res.msg)
                    this.addClose();
					this.success()
				} else {
					this.$message.error(res.msg)
				}
            })
        }
    }
}
</script>

<style lang="less">
.inner-content {
    padding :8px 8px 8px 16px
}
</style>