<template>
    <a-drawer title="添加手动生成账单" :width="920" :visible="isVisibleDrawer" :body-style="{ paddingBottom: '80px' }" @close="onClose">
        <div class="drawer-content">
            <div style="display: flex">
                <div class="inner-content">
                    <a-tree v-model="checkedKeys" @check="onCheck"
                    :selectable="false" :tree-data="roomTreeData" checkable :replace-fields="replaceFields"></a-tree>
                </div>
                <div class="inner-content">
                    <a-table :data-source="tableData" :columns="columns" :pagination="false"
                        :row-selection="{
                            selectedRowKeys: this.form.chargesIds,
                            onChange: selectionChoosed,
                        }"
                        :row-key="
                            (record, index) => {
                            return record.id;
                            }
                        ">
                        <template slot="title">
                            <span style="font-weight: 600">收费标准</span>
                        </template>
                    </a-table>
                        <a-row>
                            <a-form-model title="基础信息" :rules="rules" :model="form" ref="ruleForm">
                            <a-col :span="24">
                                <a-form-model-item label="账单名称" prop="name">
                                    <a-input v-model="form.name"></a-input>
                                </a-form-model-item>
                            </a-col>
                            </a-form-model>
                            <a-form-model title="基础信息" :model="formTime" :rules="rulesTime" ref="ruleForm">
                            <a-col :span="24">
                                <a-form-model-item label="选择时间" prop="selTime">
                                    <a-range-picker v-model="formTime.selTime"  @change="timeChange" value-format="YYYY-MM-DD HH:mm:ss"></a-range-picker>
                                </a-form-model-item>
                            </a-col>
                            </a-form-model>
                        </a-row>
                </div>
            </div>
        </div>
        <div class="drawer-footer">
            <a-button :style="{ marginRight: '8px' }" @click="onClose">
                关闭
            </a-button>
            <a-button type="primary" @click="onSubmit"> 提交 </a-button>
        </div>
    </a-drawer>
</template>

<script>
import {findEstateCascade} from "@/api/basic/estate"
import { chargesList } from "@/api/payment/chargeStandardManage";
import { manuallyGenerateBills } from "@/api/payment/payPlan"
function deleteIds(lists) {
	// 楼栋id和房屋id有重复，导致数形控件选择不可用，把除房屋id外的全改为undefined
	lists.forEach((list) => {
		if (list.childList) {
			list.id = undefined;
			deleteIds(list.childList);
		} else {
			return;
		}
	});
}
export default {
    name: 'addBillDrawer',
    props: {
		visible: Boolean,
	},
    watch: {
		visible: {
			handler(newValue) {
				this.isVisibleDrawer = newValue;
			},
			immediate: true,
		},
	},
    mounted() {
        this.getApi()  
    },
    data() {
        return {
            form: {
                estateIds: [],
                chargesIds: [],
                name: '',
                billDateStart: '',
                billDateEnd: '',
            },
            isVisibleDrawer: false,
            roomTreeData: [],
            replaceFields: {
                children: 'childList',
                title: 'name',
                key: 'id'
            },
            checkedKeys: [],
            //收费标准
            tableData: [],
            columns: [
                {title: "费用名称", dataIndex: "name"},
                {title: "计费方式", dataIndex: "billingType",
                    customRender: function (billingType) {
                        switch(billingType) {
                            case 1: return '单价*计量方式';
                            case 2: return '固定金额';
                        }
                    },
                },
                {title: "计量方式", dataIndex: "calculateType",
                    customRender: function (calculateType) {
                        switch(calculateType) {
                            case 1: return '建筑面积';
                            case 2: return '使用面积';
                            case 3: return '公摊面积';
                            case 5: return '仪表用量';
                            case 6: return '房屋数分摊';
                            case 7: return '房屋建筑面积分摊';
                            case 8: return '房屋在住人口分摊';
                        }
                    },
                },
                {title: "计量单价", dataIndex: "calculateUnit",
                    customRender: function (calculateUnit) {
                        return (calculateUnit*1).toFixed(2)
                    },
                },
                {title: "备注", dataIndex: "remarks"},
            ],
            rules: {
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
            },
            rulesTime: {
                selTime: [{ required: true, message: "请选择时间", trigger: "change" }],
            },
            formTime: {
                selTime: []
            },
        }
    },
    methods: {
        getApi() {
            findEstateCascade().then(res => {
                let data = res.data;
                // data.forEach(ele => {
                //     ele.childList.forEach(el => {
                //         el.checkable = false
                //     });
                //     ele.checkable = false
                // })
                this.roomTreeData = data
                deleteIds(this.roomTreeData);
            });
            chargesList({pageNum: 1,size: 1000}).then(res => {
                let data = res.data.rows;
                this.tableData = data;
            })
        },
        onClose() {
			this.$emit("onClose");
		},
        onCheck(checkedKeys) {
            let ids = [];
			for (let id of checkedKeys) {
				if (typeof id == "number") {
					ids.push(id);
				}
			}
            this.form.estateIds = ids;
        },
        //收费标准
        selectionChoosed(data) {
            this.form.chargesIds = data;
        },
        timeChange(val) {
            this.form.billDateStart = this.formTime.selTime[0];
            this.form.billDateEnd = this.formTime.selTime[1];
        },
        success(){
			this.$emit('success')
		},
        addClose() {
            this.onClose();
            this.form = {
                estateIds: [],
                chargesIds: [],
                name: '',
                billDateStart: '',
                billDateEnd: '',
            };
            this.checkedKeys = [];
            this.formTime.selTime = []
        },
        onSubmit() {
            manuallyGenerateBills(this.form).then(res => {
                if(res.code === 200){
					this.$message.success(res.msg)
                    this.addClose();
					this.success()
				} else {
					this.$message.error(res.msg)
				}
            })
        }
    }
}
</script>

<style lang="less">
.inner-content {
    padding :8px 8px 8px 16px
}
</style>